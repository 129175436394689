import ScrollUpArrowIcon from 'assets/images/_infoGraphics/arrowScrollUp.svg';
import { Footer } from 'components/_layout/Footer/Footer';
import Head from 'components/_layout/Head';
import { Header } from 'components/_layout/Header/Header';
import { CookiesLaw } from 'components/_modals/CookiesLaw/CookiesLaw';
import { TalkProjectPopup } from 'components/_modals/TalkProjectPopup';
import { ToastNotification } from 'components/_modals/ToastNotification/ToastNotification';
import * as Common from 'components/_universal/Common';
import React, { ReactNode, useEffect, useState } from 'react';
import { mediaQueries } from 'shared/breakpoints';
import { ROUTE_TO_PAGE_NAME_MAP } from 'shared/paths';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles from 'styles/GlobalStyles.styled';
import 'swiper/css';
import 'swiper/css/navigation';
import { Theme } from 'types/CommonTypes';
import themeVariant from 'utils/themeVariant';
import { ThemeContext } from './ThemeContext';

interface ILayoutProps {
    children: ReactNode;
    pageTitle?: string;
    location: {
        pathname: string;
    };
    className?: string;
}

const scrollUp = () => {
    scrollTo({
        behavior: 'smooth',
        top: 0,
    });
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
`;

const ScrollUpArrow = styled.div`
    bottom: 40px;
    cursor: pointer;
    height: 40px;
    position: fixed;
    right: 40px;
    z-index: 99999;

    ${mediaQueries.xs} {
        bottom: 20px;
        right: 20px;
    }
`;

export default ({ children, className }: ILayoutProps) => {
    const [headerMenuIsOpen, setHeaderMenuIsOpen] = useState(false);
    const [activeCategory, setActiveCategory] = useState('all');
    const [blogTagsSelected, setBlogTagsSelected] = useState<string[]>([]);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [headerIsVisible, setHeaderIsVisible] = useState(true);
    const [isScrollDisabled, setIsScrollDisabled] = useState(false);
    const [caseStudiesTagsSelected, setCaseStudiesTagsSelected] = useState<
        string[]
    >([]);

    const atTopPage = prevScrollPos === 0;

    const location =
        typeof window !== 'undefined'
            ? window.location
            : { pathname: 'Codahead' };
    const pageTitle = ROUTE_TO_PAGE_NAME_MAP[location.pathname];

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const handleScroll = () => {
        if (!headerMenuIsOpen) {
            const currentScrollPos = window.pageYOffset;

            setHeaderIsVisible(
                (prevScrollPos > currentScrollPos &&
                    prevScrollPos - currentScrollPos > 0) ||
                    currentScrollPos < 10,
            );

            setPrevScrollPos(currentScrollPos);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos, headerIsVisible, handleScroll]);

    return (
        <Wrapper className={className}>
            <ThemeContext.Provider
                value={{
                    caseStudiesTagsSelected,
                    setCaseStudiesTagsSelected,
                    headerMenuIsOpen,
                    setHeaderMenuIsOpen,
                    activeCategory,
                    isScrollDisabled,
                    setIsScrollDisabled,
                    setActiveCategory,
                    blogTagsSelected,
                    setBlogTagsSelected,
                    headerIsVisible,
                    setHeaderIsVisible,
                }}
            >
                <GlobalStyles
                    headerMenuIsOpen={headerMenuIsOpen}
                    isScrollDisabled={isScrollDisabled}
                />
                <Head title={pageTitle} pathname={location?.pathname} />
                <ThemeProvider theme={themeVariant[Theme.DEFAULT]}>
                    <CookiesLaw />
                    <TalkProjectPopup />
                    <Header headerIsVisible={headerIsVisible} />
                    {!headerMenuIsOpen && !atTopPage && (
                        <ScrollUpArrow onClick={() => scrollUp()}>
                            <Common.Svg src={ScrollUpArrowIcon} />
                        </ScrollUpArrow>
                    )}
                    <ToastNotification />
                    {children}
                    <Footer />
                </ThemeProvider>
            </ThemeContext.Provider>
        </Wrapper>
    );
};
