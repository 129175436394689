import energyBg from 'assets/images/Projects/energy/energyBg.png';
import energySlide1 from 'assets/images/Projects/energy/RE_01.png';
import energySlide2 from 'assets/images/Projects/energy/RE_02.png';
import energySlide3 from 'assets/images/Projects/energy/RE_03.png';

import FishialSlide1 from 'assets/images/Projects/fishial/fishial1.png';
import FishialSlide2 from 'assets/images/Projects/fishial/fishial2.png';
import FishialSlide3 from 'assets/images/Projects/fishial/fishial3.png';
import FishialSlide4 from 'assets/images/Projects/fishial/fishial4.png';
import FishialSlide5 from 'assets/images/Projects/fishial/fishial5.png';
import fishialBg from 'assets/images/Projects/fishial/fishialBg.png';
import hotelSlide1 from 'assets/images/Projects/hotels/hotele_01.png';
import hotelSlide2 from 'assets/images/Projects/hotels/hotele_02.png';
import hotelSlide3 from 'assets/images/Projects/hotels/hotele_03.png';
import hotelSlide4 from 'assets/images/Projects/hotels/hotele_04.png';
import hotelSlide5 from 'assets/images/Projects/hotels/hotele_05.png';

import hotelBg from 'assets/images/Projects/hotels/hotelsBg.png';
import mobdSlide1 from 'assets/images/Projects/mobd/mobd1.png';
import mobdSlide2 from 'assets/images/Projects/mobd/mobd_02.png';
import mobdSlide3 from 'assets/images/Projects/mobd/mobd_03.png';
import mobdSlide4 from 'assets/images/Projects/mobd/mobd_04.png';
import mobdSlide5 from 'assets/images/Projects/mobd/mobd_05.png';

import mobdBg from 'assets/images/Projects/mobd/mobdBg.png';
import nuusSlide1 from 'assets/images/Projects/nuus/nuus1.png';
import nuusSlide2 from 'assets/images/Projects/nuus/nuus_02.png';
import nuusSlide3 from 'assets/images/Projects/nuus/nuus_03.png';

import nuusBg from 'assets/images/Projects/nuus/nuusBg.png';
import spotonSlide1 from 'assets/images/Projects/spoton/spoton_01.png';
import spotonSlide2 from 'assets/images/Projects/spoton/spoton_02.png';
import spotonSlide3 from 'assets/images/Projects/spoton/spoton_03.png';

import spotonBg from 'assets/images/Projects/spoton/spotonBg.png';
import tickPredictSlide1 from 'assets/images/Projects/tickPredict/tickpredict_01.png';
import tickPredictSlide2 from 'assets/images/Projects/tickPredict/tickpredict_02.png';
import tickPredictSlide3 from 'assets/images/Projects/tickPredict/tickpredict_03.png';
import tickPredictSlide4 from 'assets/images/Projects/tickPredict/tickpredict_04.png';

import tickPredictBg from 'assets/images/Projects/tickPredict/tickPredictBg.png';
import tulerieSlide1 from 'assets/images/Projects/tulerie/tulerie1.png';
import tulerieSlide2 from 'assets/images/Projects/tulerie/tulerie_02.png';
import tulerieSlide3 from 'assets/images/Projects/tulerie/tulerie_03.png';

import tulerieBg from 'assets/images/Projects/tulerie/tulerieBg.png';
import udacitySlide1 from 'assets/images/Projects/udacity/Udacity_01.png';
import udacitySlide2 from 'assets/images/Projects/udacity/Udacity_02.png';
import udacitySlide3 from 'assets/images/Projects/udacity/Udacity_03.png';

import udacityBg from 'assets/images/Projects/udacity/udacityBg.png';
import voiceSlide1 from 'assets/images/Projects/voice/AI_Voice_01.png';
import voiceSlide2 from 'assets/images/Projects/voice/AI_Voice_02.png';

import voiceBg from 'assets/images/Projects/voice/voiceBg.png';

interface IProjectSectionProps {
    title: string;
    client: string;
    industry: string;
    region: string;
    background: string;
    overview: string;
    approach: string;
    hardware?: string[];
    highlights: string[];
    deliverables: string[];
    carouselItems: { src: string }[];
}

export const PROJECT_FISHIAL = 'fishial';
export const PROJECT_SPOTON = 'spoton';
export const PROJECT_TULERIE = 'tulerie';
export const PROJECT_TICK = 'tickpredict';
export const PROJECT_VOICE = 'voice';
export const PROJECT_MOBD = 'mobd';
export const PROJECT_NUUS = 'nuus';
export const PROJECT_RESPECT = 'respect';
export const PROJECT_UDACITY = 'udacity';
export const PROJECT_HOTELS = 'hotels';

export type TProjectName =
    | typeof PROJECT_FISHIAL
    | typeof PROJECT_SPOTON
    | typeof PROJECT_TULERIE
    | typeof PROJECT_TICK
    | typeof PROJECT_VOICE
    | typeof PROJECT_MOBD
    | typeof PROJECT_NUUS
    | typeof PROJECT_RESPECT
    | typeof PROJECT_UDACITY
    | typeof PROJECT_HOTELS;

export const PROJECT_CONTENTS: {
    [key: string]: IProjectSectionProps;
} = {
    [PROJECT_FISHIAL]: {
        title: 'Fish recognition \n' + 'and classification platform',
        client: 'Fishial.ai',
        industry: 'community',
        region: 'USA',
        background: fishialBg,
        overview:
            'Lack of globally available database with fish species. The idea was to build largest open-source fish species image library labelled for AI machine learning and seek the development of a highly accurate ope-source AI model that can identify fish species world wide. ',
        approach:
            'Let community to build up the entire database by uploading the pictures of the fish and combine AI to learn and determine the type, subtype etc. Codahead set the entire architecture for browser based service that manages community of enthusiasts, scientific marine and angler communities, let them upload images plus modify their images so the AI categorizes them.',
        hardware: [],
        highlights: [
            'Advanced AI-enhanced photo editor',
            'Complex user structure for a review process',
            'Comprehensive portal summary statistics for admins, editors and team owners',
            'CSV batch import for one-click upload of several hundreds of photos',
            'ML-based fish and species recognition',
            'Manual and automatic datasets exports',
            'Open and public teams for volunteers',
            'Machine learning engine has been continuously operating for over 4 years',
        ],
        deliverables: [
            'Architecture',
            'Full backend incl. Data management\n',
            'Web-based front end\n',
            'Machine learning engine, incl. Training for fish species recognition\n',
            'Tech Stack: React.js, Google Cloud, Rails, Type Script, PyTorch, OpenCV.js',
        ],
        carouselItems: [
            {
                src: FishialSlide1,
            },
            {
                src: FishialSlide2,
            },
            {
                src: FishialSlide3,
            },
            {
                src: FishialSlide4,
            },
            {
                src: FishialSlide5,
            },
        ],
    },
    [PROJECT_SPOTON]: {
        title: 'Mobile payment technology and a management system',
        client: 'Spoton',
        industry: 'retail, financial',
        region: 'USA',
        background: spotonBg,
        overview:
            'From idea to execution. Client approached us with an idea for POS. Needed to develop a custom, cloud-based Point-of-Sale solution for modern times with user-friendly interface that gives a venue complete view of their business and revenue streams. ',
        approach:
            'We produced a comprehensive Point of Sales solution in cooperation with the venues (like Madison Square Garden, Aramark, Live Nation and others). System was designed and produced to be highly intuitive. Transaction paths are short and efficient. Software works with and on various hardware devices or payment terminals (we injected custom software to some terminals in collaboration with Ingenico), providing support of various payment methods like chip and pin, chip and signature, CC signature, loyalty cards or Apple Pay with full capability to process off-line payments (no connection).',
        highlights: [
            'PCI certification - tier 3 (highest) certification of the system to work with payment transactions',
            'Loyalty and RFID payments - users could pay with whatever their liking',
            '40% growth of business value each year ending',
            'Hardware elements - physical kiosks buildup with full US certification for payments (similar to CE)',
            'Runs on over 50,000 devices',
        ],
        deliverables: [
            'mobile POS applications for tablets',
            'software for kitchen display system KDS',
            'Integration with POS, terminal printers, cash drawers, etc',
            'Tech Stack: Php, Android, iOS, AWS',
        ],
        hardware: [
            'Ingenico',
            'Elo',
            'Boca (terminal printers)',
            'cash drawers and other',
        ],
        carouselItems: [
            {
                src: spotonSlide1,
            },
            {
                src: spotonSlide2,
            },
            {
                src: spotonSlide3,
            },
        ],
    },
    [PROJECT_TICK]: {
        title: 'Trading platform for trading veterans',
        client: 'TickPredict',
        industry: 'Financial',
        region: 'USA',
        background: tickPredictBg,
        overview:
            'Client wanted to enter crypto-trading market by providing possibility to trade from one place on different market places, dedicated for institutional use.\n',
        approach:
            'We have created a platform that aggregates information from crypto- currency exchanges and lets the trader to carry out transactions from one place. The project involved building a universal API for integration with any stock exchange system possible. Platform ensures a user-friendly interface, consulted with veteran traders, that allows quickly create a financial portfolio on various exchanges. The platform has been integrated with stock exchanges such as Binance, Bitmex or Bitrex. Data integration included selected transactions with the possibility of performing additional operations based on a defined variable model.',
        highlights: [
            'Two-sided integration with the exchanges',
            'Multi-stage authentication',
            'Fluid and easy to operate on user interface',
            'Clear dashboard enabling data visualization and minimizing the time of comparing information from various sources',
            'Service allocation that guarantees institutional speed',
        ],
        deliverables: [
            'Architecture',
            'Full backend incl. Data management',
            'Web-based front end',
            'Exchange and payments',
            'Server side with physical allocation',
            'Tech Stack: React.js , Rx.js, Redux, PHP',
            'Node.js, Deepstream, PostgresQL, Web Sockets, RabbitMQ, Docker',
        ],
        carouselItems: [
            {
                src: tickPredictSlide1,
            },
            {
                src: tickPredictSlide2,
            },
            {
                src: tickPredictSlide3,
            },
            {
                src: tickPredictSlide4,
            },
        ],
    },
    [PROJECT_TULERIE]: {
        title: 'Peer-peer clothing rental community',
        client: 'Tulerie',
        industry: 'entertainment',
        region: 'USA',
        background: tulerieBg,
        overview:
            'Tulerie is a peer-to-peer luxury rental app with shopping, sustainability, and sharing on the mind. The Tulerie community is made up of like-minded members who are obsessed with fashion and are eager to expand their wardrobes in a way that is sustainable for both the environment and their wallets. Codahead partnered with Tulerie to help them grow their business by keeping the tech tight and smoothly working.',
        approach:
            'Once we connected with the client, the project was already ongoing. We got familiar with the project as a whole. Spent much time on properly document the entire product - each piece of it - mobile application, backend with the entire logic and frontend to administer the environment.\n' +
            'From there, team of 7 people started further development and maintenance of the ecosystem allowing the client to focus on business aspects and customers base growth.',
        highlights: [
            'Mobile application with closed (invitation only) community',
            'Full rent/borrow functionality flow',
            'Gateway integration',
            'Admin panel to oversee the rental process with cash flow',
        ],
        deliverables: [
            'Spec and Documentation incl. backend',
            'Web-based front end',
            'Backend logic and DBs',
            'Payments integration',
            'Tech Stack: MySQL, Laravel, Swift, React.js, AWS',
        ],
        carouselItems: [
            {
                src: tulerieSlide1,
            },
            {
                src: tulerieSlide2,
            },
            {
                src: tulerieSlide3,
            },
        ],
    },
    [PROJECT_VOICE]: {
        title: 'Automated AI-based medical interview',
        client: 'AIVoice',
        industry: 'healthcare',
        region: 'USA',
        background: voiceBg,
        overview:
            'The scope of the project is to carry out interdisciplinary research, development and pre-implementation work in the ICT sector to create a globally innovative technological solution. It will be an intelligent speech processing system designed for the medical industry. The developed tool allows the automation of medical interviews based on a recording of the patient-doctor dialogue. It provides accurate speech processing and, at the same time, extraction of relevant information from the recorded statements, which will be sent to a constructed form and database (question-answer system).',
        approach:
            'We approached this project by connecting with all the healthcare institutions that would provide us with needed data. After created the neural network and started to train it. Transcription of the human speech is one thing, but specialized terms and language (as it’s in polish) puts the difficulty level very high.',
        highlights: [
            'Automated speech transcription',
            'Connection with public healthcare system',
            'Automation of the medical interview',
            'AI-based symptoms suggestion and further treatment',
        ],
        deliverables: [
            'Full backend incl. Data management',
            'Web-based front end',
            'Machine learning engine, incl. Training for speech transcription',
            'Mobile applications',
            'Tech Stack: React.js, AWS, Laravel, Type Script, PyTorch',
        ],
        carouselItems: [
            {
                src: voiceSlide1,
            },
            {
                src: voiceSlide2,
            },
        ],
    },
    [PROJECT_MOBD]: {
        title: 'Fleet management system',
        client: 'Mobd',
        industry: 'automotive',
        region: 'EU',
        background: mobdBg,
        overview:
            'Fractured market providers without complex solution that would bring the latest hardware devices along with intuitive and useful online data administration panel along with driver-side communication capability.',
        approach:
            'We designed blockchain-based ODB2 reader - IoT device. We’ve create the physical prototype, coded it (embedded), verified POC and tested it. After successful POC, we prepared moved to pre and production phase. The device is armed with 5G data transmission, builtin SIM card, OBD2 port reader with ability to write as well. With secure communication, live tracking, live data and dedicated cloud storage for the entire vehicle history. Along with the device, the browser-based online service was produced as well as mobile applications that communicate with the device via data or BLE.',
        highlights: [
            'OBD2 dongle to be used with cars and read car data',
            'Online administration services available 24/7',
            'Mobile application for data sync, dongle communication',
            'Secure and true vehicles history kept in blockchain',
            'AI-driven Analytics & Reporting',
        ],
        deliverables: [
            'Full backend incl. Data management',
            'Web-based front end',
            'IoT device with GPRS data',
            'Mobile application',
            'Tech Stack: Blockchain, React.js',
            'Hardware: Acc & Gyro, PCB, C++, Embedded software, MQTT',
        ],
        carouselItems: [
            {
                src: mobdSlide1,
            },
            {
                src: mobdSlide2,
            },
            {
                src: mobdSlide3,
            },
            {
                src: mobdSlide4,
            },
            {
                src: mobdSlide5,
            },
        ],
    },
    [PROJECT_NUUS]: {
        title: 'Nuus - sports managing system',
        client: 'Piast Gliwice',
        industry: 'sports',
        region: 'UE',
        background: nuusBg,
        overview:
            'Nuus is Saas product, browser-based administration panel for managing sports club multimedia content with additional functionalities - end-customers mobile applications, AI module, online event ticketing system or even stadium banner display module. All of that lets our customers to manage their media of one service, from creation to publishing posts and videos. Codahead designed architecture, built databases, backend, frontend, designed visuals, as well as mobile application, ticketing system, ending with coding interactive game that event provider (club) can integrate with their stadium digital board. Nuus is complex service that, thanks to UX and UI, makes it easy to use.\n',
        approach:
            'Nuus has been created with collaboration with top tier polish football club. The requirements - to manage most of the fans operations under one product. Codahead was able to deliver browser administration panel to manage daily fans operations, operate merchandise under dedicated mobile applications, to sell and serve parking tickets. Also to raise fans knowledge and attach them to the team by AI module to identify players on the field and feed them with additional links and data. Eventually - additional module to let the users play the interactive game on stadium screen during the breaks.',
        highlights: [
            'Club admin panel with: schedule, notifications, social media, online store',
            'External modules - ticketing, merchandised',
            'AI based live objects identification',
            'Interactive stadium game for fans',
        ],
        deliverables: [
            'Full backend incl. Data management',
            'Web-based frontend with admin panel',
            'Machine learning engine - players identification',
            'Tech Stack: Swift, Kotlin, MySQL, API feeds, AI module, React.js, Php, PyTorch',
        ],
        carouselItems: [
            {
                src: nuusSlide1,
            },
            {
                src: nuusSlide2,
            },
            {
                src: nuusSlide3,
            },
        ],
    },
    [PROJECT_RESPECT]: {
        title: 'Green energy hardware control devices',
        client: 'Respect Energy',
        industry: 'energy',
        region: 'EU',
        background: energyBg,
        overview:
            'Respect Energy brings together independent power producers, accredited and institutional investors holding assets in renewables or undertaking investments in wind and solar green energy power plants. They partnered up with Codahead to work on hardware and software /embedded/ product- the device that lets them efficiently measure the amount of produced solar power as well as manage certain internally required power plan parameters with some safety protocols and watch dogs on place',
        approach:
            'Codahead visited several European sites and determined all the inputs and outputs so the device is capable to work with many sites and connect to measurement tools /sensors etc/ and standards. We’ve determined the PCB architecture and all the required by Respect Energy additions for the future use. The device was designed to operate between -20 to +40 degrees Celsius and meet industrial deployment standards and tamper protection, supported by both IC and enclosure.\n' +
            'We’ve built it the way to ensure it is ready for CE and RoHS / FC certification once ready for production.',
        highlights: [
            'Collect and transform data captured via a multitude of protocols and sync it with the cloud.',
            'Allow connectivity and remote access via cellular. Provide real-time time status of the device (battery status, temperature, GPS, humidity)',
            'Feature ports and slots - USB (charging and tether), ethernet ports, COM ports, power button, reset button, pairing button, Status LEDs, Battery port, microSD, eSIM/SIM)',
            'Secure data transmission HTTPS AES128 and allow configuration via a secured web portal.',
        ],
        deliverables: [
            'Hardware, electronics, PCB, embedded software, communication',
            'Tech Stack: C#, Raspberry, WindowsForm, Ms WPF, MQTT',
        ],
        carouselItems: [
            {
                src: energySlide3,
            },
            {
                src: energySlide1,
            },
            {
                src: energySlide2,
            },
        ],
    },
    [PROJECT_UDACITY]: {
        title: 'Enterprise schooling educational system',
        client: 'Udacity',
        industry: 'information technology',
        region: 'USA',
        background: udacityBg,
        overview:
            'Udacity is an American educational organization founded in 2011, offering massive open online courses for students as well as teams and enterprise. Their university credit courses or nano-degree programs are very popular among working class that wish to lift their professional expertise and value at the employee market. They partnered with Codahead to increase their enterprise presence.\n',
        approach:
            "Our role in this project was to be responsible for the enterprise experience. The challenge was making sure that the platform would be easy enough, so it could accommodate any enterprise's needs without overwhelming them with too many features or confusing interface elements. First, we conducted a UI/UX audit to make sure we knew exactly what problems were there to solve. Then we designed the new solutions and implemented them.",
        highlights: [
            'Updated design pattern adopted with impressive success',
            'a lot of positive reviews from end enterprise users',
            'increased interest from the corporate workforce',
        ],
        deliverables: [
            'User Experience audit',
            'User Interaction design',
            'Enterprise flow developed and delivered',
            'Tech Stack: React.js, WordPress, Google Cloud, Rails, Type Script, PyTorch',
        ],
        carouselItems: [
            {
                src: udacitySlide1,
            },
            {
                src: udacitySlide2,
            },
            {
                src: udacitySlide3,
            },
        ],
    },
    [PROJECT_HOTELS]: {
        title: 'Hotel reservation system',
        client: 'NDA',
        industry: 'hospitality',
        region: 'UE',
        background: hotelBg,
        overview:
            'NDA is one of the pioneers in a new category on the European market offering private purpose-built student accommodation (PBSA). This business step is in line with a substantial shortfall of student housing in Europe. This refers both to international and domestic students. Codahead started to work with Zeitraum to address their need of fully automated and connected to their software system kiosk that would let the renters to register, gain access and manage their booking along with specific hotel spaces with special access.',
        approach:
            'We investigated the building and functional plan of their housings. Based on their operational knowledge and full scope with needs, we were able to design, verify and build functional physical kiosk that lets students to choose the right to them apartment, book it, pay and get RFID access card that grants access to paid for areas within the house. We delivered few working kiosks (end product) and work on improvements and another batch.',
        highlights: [
            'Physical device to let students to self check-in',
            'Complete rental process with payment for the reservation',
            'Granting time-limited (rental limited) access to rooms and facilities',
            'Facial recognition to grant access (AI driven)',
        ],
        deliverables: [
            'Full backend incl. Data management',
            'Web-based front end admin panel',
            'Machine learning engine, incl. Training for facial/objects recognition',
            'iOS and Android applications',
            'Tech Stack: React.js, Google Cloud, Laravel, PyTorch, Kotlin, Swift, Node.js',
        ],
        carouselItems: [
            {
                src: hotelSlide1,
            },
            {
                src: hotelSlide2,
            },
            {
                src: hotelSlide3,
            },
            {
                src: hotelSlide4,
            },
            {
                src: hotelSlide5,
            },
        ],
    },
};
